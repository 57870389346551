var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("Page", [
    _c(
      "div",
      { staticClass: "home-page" },
      [
        _c("zui-header", { attrs: { hasLine: true } }),
        _c(
          "div",
          { staticClass: "content-box" },
          [
            _c("div", { staticClass: "banner-box" }, [
              _c("img", {
                attrs: {
                  src: "https://www.achencybe.com/img/pc/detail/challenger/3-1.jpg",
                },
              }),
              _c(
                "div",
                {
                  staticClass: "btn-box",
                  on: {
                    click: function ($event) {
                      return _vm.goToBuy()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("car_list.buy_now")))]
              ),
              _c("img", {
                staticClass: "btn-img",
                attrs: {
                  src: "https://www.achencybe.com/img/pc/detail/challenger/btn-1.png",
                  alt: "",
                },
              }),
            ]),
            _c("div", { staticClass: "banner-box con" }, [
              _c("img", {
                staticClass: "logo",
                attrs: {
                  src: "https://www.achencybe.com/img/pc/detail/challenger/con_logo.png",
                },
              }),
              _c("div", {}, [_vm._v(_vm._s(_vm.$t("car_list.dodge.style_1")))]),
              _c("p", { staticStyle: { "font-weight": "100" } }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("car_list.dodge.show_page.technology_1")) +
                    " "
                ),
              ]),
              _c("p", { staticStyle: { "font-weight": "100" } }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("car_list.dodge.show_page.technology_2")) +
                    " "
                ),
              ]),
              _c("p", [
                _vm._v(_vm._s(_vm.$t("car_list.dodge.show_page.technology_3"))),
              ]),
              _c("img", {
                staticClass: "img",
                attrs: {
                  src: "https://www.achencybe.com/img/pc/detail/challenger/3-2.png",
                },
              }),
            ]),
            _c(
              "div",
              {
                staticClass: "banner-box",
                staticStyle: { "padding-bottom": "40px" },
              },
              [
                _c("features", {
                  attrs: {
                    title: "car_list.dodge.show_page.technology_5",
                    "sub-title": this.subTitle,
                    language: this.$i18n.locale,
                    list: _vm.list,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticStyle: { margin: "40px 0", "font-size": "12px" } },
              [
                _c("p", [
                  _vm._v(
                    " " + _vm._s(_vm.$t("car_list.care_plus_remark_1")) + " "
                  ),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "carousel-box",
                staticStyle: { "margin-bottom": "180px" },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "left",
                    on: {
                      click: function ($event) {
                        return _vm.$refs.carousel.prev()
                      },
                    },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: "https://www.achencybe.com/img/pc/detail/challenger/left.png",
                      },
                    }),
                  ]
                ),
                _c(
                  "el-carousel",
                  {
                    ref: "carousel",
                    attrs: {
                      interval: 5000,
                      arrow: "never",
                      height: _vm.bannerHeight,
                    },
                  },
                  _vm._l(5, function (item) {
                    return _c("el-carousel-item", { key: item }, [
                      _c("img", {
                        attrs: {
                          src:
                            "https://www.achencybe.com/img/pc/detail/challenger/3-4-" +
                            item +
                            ".jpg",
                          alt: "",
                        },
                      }),
                    ])
                  }),
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "right",
                    on: {
                      click: function ($event) {
                        return _vm.$refs.carousel.next()
                      },
                    },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: "https://www.achencybe.com/img/pc/detail/challenger/right.png",
                        alt: "",
                      },
                    }),
                  ]
                ),
              ],
              1
            ),
            _c("introduce", { attrs: { introduceList: _vm.introduceList } }),
            _c("img", {
              staticStyle: { width: "100%", "max-width": "870px" },
              attrs: {
                src: "https://www.achencybe.com/img/pc/detail/challenger/text-1.png",
                alt: "",
              },
            }),
            _c("br"),
            _c("img", {
              staticStyle: {
                "max-width": "1375px",
                width: "100%",
                margin: "100px auto",
              },
              attrs: {
                src: "https://www.achencybe.com/img/pc/detail/challenger/3-6.jpg",
                alt: "",
              },
            }),
            _c("detail-footer", {
              attrs: {
                title: "Dodge Challenger SRT",
                img: "https://www.achencybe.com/img/pc/detail/challenger/3-2.png",
              },
              on: {
                buy: function ($event) {
                  return _vm.goToBuy()
                },
              },
            }),
          ],
          1
        ),
        _c("zui-footer"),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }