<template>
  <Page>
    <div class="home-page">
      <zui-header :hasLine="true" />
      <div class="content-box">
        <div class="banner-box">
          <img src="https://www.achencybe.com/img/pc/detail/challenger/3-1.jpg" />
          <div class="btn-box" @click="goToBuy()">{{$t('car_list.buy_now')}}</div>
          <img
            class="btn-img"
            src="https://www.achencybe.com/img/pc/detail/challenger/btn-1.png"
            alt=""
          />
        </div>
        <div class="banner-box con">
          <img src="https://www.achencybe.com/img/pc/detail/challenger/con_logo.png" class="logo" />
          <div style="">{{$t('car_list.dodge.style_1')}}</div>
          <p style="font-weight: 100">
            {{$t('car_list.dodge.show_page.technology_1')}}
          </p>
          <p style="font-weight: 100">
            {{$t('car_list.dodge.show_page.technology_2')}}
          </p>
          <p>{{$t('car_list.dodge.show_page.technology_3')}}</p>
          <img class="img" src="https://www.achencybe.com/img/pc/detail/challenger/3-2.png" />
        </div>
        <div class="banner-box" style="padding-bottom: 40px">
          <features
            title="car_list.dodge.show_page.technology_5"
            :sub-title="this.subTitle"
            :language="this.$i18n.locale"
            :list="list"
          />
        </div>
        <div style="margin: 40px 0; font-size: 12px">
          <p>
            {{$t('car_list.care_plus_remark_1')}}
          </p>
        </div>
        <div class="carousel-box" style="margin-bottom: 180px">
          <div class="left" @click="$refs.carousel.prev()">
            <img src="https://www.achencybe.com/img/pc/detail/challenger/left.png" />
          </div>
          <el-carousel
            :interval="5000"
            arrow="never"
            :height="bannerHeight"
            ref="carousel"
          >
            <el-carousel-item v-for="item in 5" :key="item">
              <img
                :src="'https://www.achencybe.com/img/pc/detail/challenger/3-4-' + item + '.jpg'"
                alt=""
              />
            </el-carousel-item>
          </el-carousel>
          <div class="right" @click="$refs.carousel.next()">
            <img src="https://www.achencybe.com/img/pc/detail/challenger/right.png" alt="" />
          </div>
        </div>
        <introduce :introduceList="introduceList" />
        <img
          style="width: 100%; max-width: 870px"
          src="https://www.achencybe.com/img/pc/detail/challenger/text-1.png"
          alt=""
        />
        <br />
        <img
          style="
            max-width: 1375px;
            width: 100%;
            margin: 100px auto;
          "
          src="https://www.achencybe.com/img/pc/detail/challenger/3-6.jpg"
          alt=""
        />
        <detail-footer
          title="Dodge Challenger SRT"
          @buy="goToBuy()"
          img="https://www.achencybe.com/img/pc/detail/challenger/3-2.png"
        />
      </div>
      <zui-footer />
    </div>
  </Page>
</template>
<script>
// import "../../less/home-page.less";
// import "../../less/challenger-show.less";
import Page from "../../../components/Page";
import ZuiHeader from "../../../components/Header";
import ZuiFooter from "../../../components/Footer";
import features from "@/components/features";
import introduce from "@/components/introduce";
import DetailFooter from "@/components/detail-footer";

export default {
  components: { Page, ZuiHeader, ZuiFooter, features, introduce, DetailFooter },
  data() {
    return {
      bannerHeight: "1050px",
      list: [],
      subTitle: [
        "car_list.dodge.show_page.technology_6",
      ],
      introduceList: [
        {
          img: "https://www.achencybe.com/img/pc/detail/challenger/3-5-1.jpg",
          title: "car_list.dodge.show_page.introduces.item1.title",
          content:"car_list.dodge.show_page.introduces.item1.content",
          contImg: "",
          subContent: [""],
        },
        {
          img: "https://www.achencybe.com/img/pc/detail/challenger/3-5-2.jpg",
          title: "car_list.dodge.show_page.introduces.item2.title",
          content:"car_list.dodge.show_page.introduces.item2.content",
          contImg: "https://www.achencybe.com/img/pc/detail/challenger/intro_img_2.png",
          subContent: [""],
        },
        {
          img: "https://www.achencybe.com/img/pc/detail/challenger/3-5-3.jpg",
          title: "car_list.dodge.show_page.introduces.item3.title",
          content:"car_list.dodge.show_page.introduces.item3.content",
          contImg: "",
          subContent: [
            "car_list.dodge.show_page.introduces.item3.sub_content.sub_1",
            "car_list.dodge.show_page.introduces.item3.sub_content.sub_2",
          ],
        },
        {
          img: "https://www.achencybe.com/img/pc/detail/challenger/3-5-4.jpg",
          title: "car_list.dodge.show_page.introduces.item4.title",
          content:"car_list.dodge.show_page.introduces.item4.content",
          contImg: "",
          subContent: [""],
        },
      ],
    };
  },
  mounted() {
    this.height = window.innerHeight + "px";
    this.bannerHeight = (1025 / 1920) * (window.innerWidth >= 1200 ? window.innerWidth : 1200) + "px";
    window.addEventListener("resize", () => {
      this.$nextTick(() => {
        this.height = window.innerHeight - 120 + "px";
        this.bannerHeight = (1025 / 1920) * (window.innerWidth >= 1200 ? window.innerWidth : 1200) + "px";
      });
    });
    this.getCarDetail();
  },
  methods: {
    goToBuy: function () {
      this.$router.push({
        path: "/pages/public/OrderPage",
        query: { cartype: "carlistchallenger" },
      });
    },
    getCarDetail() {
      this.$api
        .getCarDetail({
          carModelName: "Challgner SRT",
        })
        .then((res) => {
          if (res.code === 0) {
            res.data.listAccessoriesList.sort((a, b) => {
              return a.partsIndex - b.partsIndex;
            });
            this.list = [
              {
                img: "https://www.achencybe.com/img/pc/detail/challenger/3-3-1.jpg",
                title: ["car_list.dodge.show_page.items.item1.title"],
                content:"car_list.dodge.show_page.items.item1.content",
                a: "car_list.dodge.show_page.items.a",
                price: res.data.listAccessoriesList[0].partsPrice,
                price_dollar: res.data.listAccessoriesList[0].partsPriceDollar,
              },
              {
                img: "https://www.achencybe.com/img/pc/detail/challenger/3-3-2.jpg",
                title: ["car_list.dodge.show_page.items.item2.title"],
                content:"car_list.dodge.show_page.items.item2.content",
                price: res.data.listAccessoriesList[1].partsPrice,
                price_dollar: res.data.listAccessoriesList[1].partsPriceDollar,
              },
              {
                img: "https://www.achencybe.com/img/pc/detail/challenger/3-3-3.jpg",
                title: ["car_list.dodge.show_page.items.item3.title"],
                content:"car_list.dodge.show_page.items.item3.content",
                price: res.data.listAccessoriesList[2].partsPrice,
                price_dollar: res.data.listAccessoriesList[2].partsPriceDollar,
              },
              {
                img: "https://www.achencybe.com/img/pc/detail/challenger/3-3-4.jpg",
                title: ["car_list.dodge.show_page.items.item4.title"],
                content:"car_list.dodge.show_page.items.item4.content",
                price: res.data.listAccessoriesList[3].partsPrice,
                price_dollar: res.data.listAccessoriesList[3].partsPriceDollar,
              },
            ];
          }
        })
        .catch((err) => {
          console.error("getCarDetail", err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.home-page {
  background-image: url("https://www.achencybe.com/img/pc/detail/challenger/bg.png");
  background-size: 100%;
  background-position-y: -20%;
  color: #fff;

  .content-box {
    width: 100%;

    .banner-box + .banner-box {
      margin-top: 40px;
    }

    .banner-box {
      background-color: #000;
      max-width: 1375px;
      margin: 0 auto;
      box-shadow: 2px 2px 14px 2px #000;
      position: relative;

      img {
        width: 100%;
      }

      .btn-box {
        position: absolute;
        bottom: 16%;
        left: calc(50% - 230px);
        width: 460px;
        height: 50px;
        line-height: 50px;
        background-color: #3d7bfe;
        color: #fff;
        box-shadow: 2px 6px 14px 2px #000;
      }

      .btn-img {
        position: absolute;
        bottom: 2%;
        left: calc(50% - 64px);
        width: 128px;
        color: #fff;
      }
    }
    .con {
      padding: 60px 0;
      .logo {
        width: 316px;
      }
      div {
        font-size: 30px;
        margin: 14px 0 15px;
      }
      p + p {
        margin-top: 6px;
      }
      .img {
        width: 1000px;
        margin-bottom: 60px;
      }
    }
    @media screen and (max-width: 500px) {
      .banner-box {
        width: 90%;
      }
      .banner-box + .banner-box {
        margin-top: 20px;
      }
    }
  }
}
::v-deep .el-carousel__indicator--horizontal {
  padding: 26px 4px;
}

::v-deep .el-carousel__button {
  position: relative;
  width: 16px;
  height: 16px;
  border-radius: 16px;
  opacity: 1;
}

::v-deep .el-carousel__button:after {
  position: absolute;
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 16px;
  top: 3px;
  left: 3px;
}

::v-deep .is-active .el-carousel__button:after {
  background-color: #007bff;
}
</style>